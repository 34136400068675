import { FetchGlossariesItem } from '@/types/fetchGlossariesFromAzure'
import { FetchLinksFromAzureResponseData } from '@/types/fetchLinksFromAzure'
import { Elements } from '@kontent-ai/delivery-sdk'

export function mapRichTextLinks(
	linksArray: FetchLinksFromAzureResponseData['data']['description']['links'],
): Elements.RichTextElement['links'] {
	const links: Elements.RichTextElement['links'] = []

	for (const link of linksArray) {
		links.push({
			linkId: link.id,
			urlSlug: link.urlSlug,
			codename: link.codename,
			type: link.type,
		})
	}

	return links
}

export function mapRichTextImages(
	imagesArray: FetchGlossariesItem['description']['images'],
): Elements.RichTextElement['images'] {
	const images: Elements.RichTextElement['images'] = []

	for (const imageRaw of imagesArray) {
		images.push({
			imageId: imageRaw.id,
			url: imageRaw.url,
			description: imageRaw.description,
			height: imageRaw.height,
			width: imageRaw.width,
		})
	}

	return images
}

export function normalizeRichtextElementFromAzure(
	richtextElement: FetchGlossariesItem['description'],
) {
	return {
		...richtextElement,
		links: mapRichTextLinks(richtextElement.links),
		images: mapRichTextImages(richtextElement.images),
	} as unknown as Elements.RichTextElement
}
