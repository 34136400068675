import { useIsScreenDown } from '@/hooks/useIsScreenDown'
import { UiResources as UiResourcesModel } from '@/kontent/content-types'
import DownloadList, {
	DEFAULT_HIDDENS_FOR_DOWNLOAD_LIST,
} from '@/legacy-ported/components/syllabus/DownloadList'
import { DownloadListField } from '@/types/downloadList'
import { getLinkedItems } from '@/utils'
import { useMemo } from 'react'
import type { RichtextSectionProps } from '.'

const CustomHiddenDownloadListFields: DownloadListField[] = [
	...DEFAULT_HIDDENS_FOR_DOWNLOAD_LIST,
	'stage',
	'resourceType',
	'year',
]

export default function UiResources({
	linkedItem,
	linkedItems,
}: RichtextSectionProps<UiResourcesModel>) {
	const isScreenDownMd = useIsScreenDown('md')
	const isScreenDownLg = useIsScreenDown('lg')

	const hiddenFieldsForDownloadList = useMemo<DownloadListField[]>(() => {
		const hiddenOnDownLg: DownloadListField[] = [
			'resourceType',
			'stage',
			'year',
		]

		if (isScreenDownMd) {
			return [
				...CustomHiddenDownloadListFields,
				...hiddenOnDownLg,
				'fileSize',
				'fileType',
			]
		}
		if (isScreenDownLg) {
			return [...CustomHiddenDownloadListFields, ...hiddenOnDownLg]
		}
		return CustomHiddenDownloadListFields
	}, [isScreenDownMd, isScreenDownLg])

	const resourceContainers = getLinkedItems(
		linkedItem.elements.resource_containers,
		linkedItems,
	)

	return resourceContainers?.length ? (
		<div className="">
			<DownloadList
				files={resourceContainers}
				hiddenFields={hiddenFieldsForDownloadList}
				hideCheckbox={true}
			/>
		</div>
	) : null
}
