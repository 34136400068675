import { log } from 'next-axiom'
import { Component, ReactNode } from 'react'
class ErrorBoundary extends Component<
	{
		children: ReactNode
	},
	{
		hasError: boolean
	}
> {
	constructor(props) {
		super(props)

		// Define a state variable to track whether is an error or not
		this.state = { hasError: false }
	}
	static getDerivedStateFromError() {
		// Update state so the next render will show the fallback UI
		return { hasError: true }
	}
	componentDidCatch(error, errorInfo) {
		// You can use your own error logging service here
		console.error({ error, errorInfo })
		log.error('ErrorBoundary caught an error', {
			error,
			errorInfo,
			request: {
				host: window.location.host || '',
			},
			vercel: {
				deploymentUrl: process.env.NEXT_PUBLIC_VERCEL_URL || '',
			},
		})
		if (process.env.NODE_ENV !== 'development') {
			setTimeout(() => {
				window.location.href = '/?redirectreason=error'
			}, 1000)
		}
	}
	render() {
		// Check if the error is thrown
		if (this.state.hasError) {
			// You can render any custom fallback UI
			return <></>
		}

		// Return children components in case of no error

		return this.props.children
	}
}

export default ErrorBoundary
