import { TaxoStage, TaxoSyllabustype } from '@/kontent/taxonomies'
import { isIntersect } from '@/utils'

export const filterAssetSelectedStages = (
	selectedFilterStages: TaxoStage[],
	_taxoStages: TaxoStage[],
	_taxoSyllabusType: TaxoSyllabustype[],
) => {
	return selectedFilterStages.some((stage) => {
		// if stage is life skills, check if syllabus type is life skills
		if (stage.includes('life_skills@')) {
			const _stages = stage.replace('life_skills@', '').split('___')
			return (
				isIntersect(_stages, _taxoStages) &&
				_taxoSyllabusType.includes('life_skills')
			)
		}
		return _taxoStages.includes(stage)
	})
}
