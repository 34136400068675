import { UiAccordion } from '@/kontent/content-types'
import { Accordion, AccordionGroup } from 'nsw-ds-react'
import type { RichtextSectionProps } from '.'
import { getLinkedItems } from '../contexts/KontentHomeConfigProvider'
import RichText from '../RichText'
export default function ui_accordion({
	linkedItem,
	linkedItems,
	mappings,
}: RichtextSectionProps<UiAccordion>) {
	const { items: _items } = linkedItem.elements
	const items = getLinkedItems(_items, linkedItems)

	return items?.length ? (
		<div>
			<AccordionGroup>
				{items.map((item) => {
					return (
						<Accordion
							key={item.system.id}
							body={
								<RichText
									richTextElement={item.elements.content}
									linkedItems={linkedItems}
									mappings={mappings}
								/>
							}
							header={item.elements.title.value}
						></Accordion>
					)
				})}
			</AccordionGroup>
		</div>
	) : null
}
