import type { Mapping } from '@/types'
import { TDownloadListFile } from '@/types/downloadList'
import getUrlFromMapping from '@/utils/getUrlFromMapping'
import {
	isAssetWithRawElement,
	isResourceContainer,
	isUiVideoTile,
	isWebLinkext,
	isWebLinkint,
	isWebLinkTeachingadviceExtended,
	isWebLinkVideo,
} from '@/utils/type_predicates'

export const getUrlFromFileObj = (
	file: TDownloadListFile,
	mappings: Mapping[],
) => {
	if (isAssetWithRawElement(file)) return file.url
	if (isWebLinkext(file)) return file.elements.link_url.value
	if (isWebLinkint(file))
		return getUrlFromMapping(mappings, file.system.codename)
	if (isWebLinkTeachingadviceExtended(file)) {
		return file.link
	}
	if (isWebLinkVideo(file) || isUiVideoTile(file)) {
		return file.elements.video_url.value
	}

	if (isResourceContainer(file)) {
		const fileNameWithExtension =
			file.elements.file_name.value ||
			file.elements.asset?.value?.[0].name.toLowerCase()
		return `/file/${file.system.id}/${fileNameWithExtension}`
	}
}
