import { MATHML_TAGS } from '@/constants/mathml'
import { MathContent } from '@/kontent/content-types'
import { waitUntil } from '@/utils/waitUntil'
import { log } from 'next-axiom'
import Script from 'next/script'
import { useEffect } from 'react'
import sanitizeHtml from 'sanitize-html'
import type { RichtextSectionProps } from '.'
import SanitisedHTMLContainer from '../SanitisedHTMLContainer'

const ALLOWED_TAGS = sanitizeHtml.defaults.allowedTags.concat(...MATHML_TAGS)

const getTypesetPromise = async () => {
	const typesetPromise = await new Promise((resolve) => {
		waitUntil(
			() => (window as any).MathJax?.typesetPromise,
			() => {
				resolve((window as any).MathJax?.typesetPromise)
			},
		)
	})
	return typesetPromise as any
}

const typeset = async () => {
	const typesetPromise = await getTypesetPromise()
	if (typesetPromise && (window as any).MathJaxPromise) {
		if ((window as any).MathJaxPromise) {
			;(window as any).MathJaxPromise = (
				window as any
			).MathJaxPromise.then(() => {
				return typesetPromise()
			})
		}
	} else {
		if (!(window as any).MathJaxPromise) {
			;(window as any).MathJaxPromise = Promise.resolve()
			typeset()
		}
	}
}

const Inner = ({ richtextValue }) => {
	useEffect(() => {
		try {
			typeset()
		} catch (error) {
			console.log(
				'🚀 ~ file: math_content.tsx:39 ~ useEffect ~ error:',
				error,
			)
			log.error('MathJax failed to load', {
				...error,
				request: {
					host: window.location.host || '',
				},
				vercel: {
					deploymentUrl: process.env.NEXT_PUBLIC_VERCEL_URL || '',
				},
			})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [richtextValue])

	return (
		<>
			<SanitisedHTMLContainer
				allowedTags={ALLOWED_TAGS}
				allowedAttributes={false}
				css={{
					'&&:not(:nth-child(-n+2))': {
						marginTop: '2.25rem',
					},
					[`.TeachingSupportCard &&:not(:nth-child(-n+2)),
						.glossary-body__definition &&:not(:nth-child(-n+2))`]: {
						marginTop: '1rem',
					},
				}}
			>
				{richtextValue}
			</SanitisedHTMLContainer>
		</>
	)
}

export default function math_content({
	linkedItem,
}: RichtextSectionProps<MathContent>) {
	return linkedItem.elements.content.value ? (
		<>
			<Inner richtextValue={linkedItem.elements.content.value} />
			<Script
				strategy="afterInteractive"
				src="https://cdn.jsdelivr.net/npm/mathjax@4.0.0-beta.6/tex-mml-svg.js"
			/>
		</>
	) : null
}
