import { fetchLinks } from '@/fetchers/fetchLinks'
import { FetchLinksFromAzureResponseData } from '@/types/fetchLinksFromAzure'
import convertLinkedItemsArrayToRecordFromAzure from '@/utils/convertModularContentArrayToRecordFromAzure'
import { normalizeRichtextElementFromAzure } from '@/utils/richtextFromAzureUtils'
import { ILink } from '@kontent-ai/delivery-sdk'
import { TrapFocus } from '@mui/base'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Paper from '@mui/material/Paper'
import Popper from '@mui/material/Popper'
import useTheme from '@mui/material/styles/useTheme'
import { useQuery } from '@tanstack/react-query'
import clsx from 'clsx'
import {
	Dispatch,
	MouseEvent,
	ReactNode,
	SetStateAction,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react'
import { useKontentHomeConfig } from '../contexts/KontentHomeConfigProvider'
import Icon from '../Icon'
import { Loading } from '../Loading'
import RichText from '../RichText'
import { LinkPropsExtended } from './RichtextLinkWrapper'

interface RichtextLinkGlossaryNewProps {
	link: ILink
	children: (_: LinkPropsExtended) => ReactNode
	rootGlossaryPopoverData?: FetchLinksFromAzureResponseData
	setGlossaryPopoverParentData?: Dispatch<
		SetStateAction<FetchLinksFromAzureResponseData>
	>
}

export const RichtextLinkGlossaryNew = ({
	link,
	rootGlossaryPopoverData,
	setGlossaryPopoverParentData,
	children,
}: RichtextLinkGlossaryNewProps) => {
	const theme = useTheme()
	const { mappings, preview } = useKontentHomeConfig()
	const refArrow = useRef<HTMLDivElement>(null)
	const refPopper = useRef<HTMLDivElement>(null)
	const componentRef = useRef<HTMLSpanElement>(null)
	const [isVisible, setIsVisible] = useState(false)
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
	const [popperModifiers, setPopperModifiers] = useState([])

	// Set up intersection observer
	useEffect(() => {
		const observer = new IntersectionObserver(
			([entry]) => {
				setIsVisible(entry.isIntersecting)
			},
			{
				threshold: 0.1,
			},
		)

		if (componentRef.current) {
			observer.observe(componentRef.current)
		}

		return () => {
			observer.disconnect()
		}
	}, [])

	const {
		data: response,
		isFetched,
		isFetching,
	} = useQuery({
		queryKey: [link.codename, preview],
		queryFn: () =>
			fetchLinks({
				codenames: [link.codename],
				preview,
			}),
		staleTime: Infinity,
		keepPreviousData: true,
		enabled: isVisible, // Only fetch when component is visible
	})

	const _glossaryLinkResponseData = isFetched
		? response.json?.data?.[0] || null
		: null

	const [stateGlossaryData, setGlossaryData] = useState(
		_glossaryLinkResponseData,
	)

	const handleClose = useCallback(() => {
		setAnchorEl(null)
		if (setGlossaryPopoverParentData && rootGlossaryPopoverData) {
			setGlossaryPopoverParentData(rootGlossaryPopoverData)
		} else {
			setGlossaryData(_glossaryLinkResponseData)
		}
	}, [
		_glossaryLinkResponseData,
		rootGlossaryPopoverData,
		setGlossaryPopoverParentData,
	])

	const handleClick = (ev: MouseEvent<HTMLElement>) => {
		ev.preventDefault()
		if (setGlossaryPopoverParentData) {
			setGlossaryPopoverParentData(_glossaryLinkResponseData)
			return
		}
		setAnchorEl(anchorEl ? null : ev.currentTarget)
		setTimeout(() => {
			if (refPopper.current) {
				refPopper.current.focus()
			}
		}, 0)
	}

	useEffect(() => {
		if (isFetched) {
			setGlossaryData(_glossaryLinkResponseData)
		}
	}, [_glossaryLinkResponseData, isFetched])

	const open = Boolean(anchorEl)
	const id = open
		? `popper-${_glossaryLinkResponseData?.codename}`
		: undefined

	// Handle Escape key press
	useEffect(() => {
		const handleKeyDown = (event) => {
			if (event.key === 'Escape') {
				handleClose()
			}
		}

		// Add event listener when the Popper is open
		if (open) {
			window.addEventListener('keydown', handleKeyDown)
		}

		// Clean up the event listener when the Popper is closed or the component unmounts
		return () => {
			window.removeEventListener('keydown', handleKeyDown)
		}
	}, [open, handleClose]) // Re-run the effect when `open` changes

	useEffect(() => {
		if (open) {
			setTimeout(() => {
				setPopperModifiers([
					{
						name: 'flip',
						enabled: true,
						options: {
							fallbackPlacements: ['top', 'bottom'],
							altBoundary: true,
							padding: 8,
						},
					},
					{
						name: 'preventOverflow',
						enabled: true,
						options: {
							padding: 8,
						},
					},
					{
						name: 'offset',
						options: {
							offset: [0, 8],
						},
					},
					{
						name: 'arrow',
						enabled: true,
						options: {
							element: refArrow.current,
						},
					},
				])
			}, 0)
		}
	}, [open])

	const glossaryData = useMemo(() => {
		return !rootGlossaryPopoverData
			? _glossaryLinkResponseData?.data
			: stateGlossaryData?.data
	}, [_glossaryLinkResponseData, stateGlossaryData, !rootGlossaryPopoverData])

	if (!glossaryData && !isFetching && isFetched) {
		console.error(`🚀 ~ RichtextLinkGlossaryNew.tsx:201 ~ :
			link codename: ${link.codename}
			rootGlossaryPopoverData: ${rootGlossaryPopoverData}
			stateGlossaryData: ${stateGlossaryData}
			_glossaryLinkResponseData: ${_glossaryLinkResponseData}
		`)
	}

	return (
		<ClickAwayListener onClickAway={handleClose}>
			<span className="relative inline-flex" ref={componentRef}>
				{!isFetched || isFetching ? (
					<Loading />
				) : glossaryData ? (
					<span
						className="underline underline-offset-4 decoration-dotted decoration-nsw-brand-dark cursor-pointer RichtextLinkGlossaryNew__term [[role=tooltip]_&]:decoration-current"
						aria-controls={id}
						aria-haspopup="dialog"
						onClick={handleClick}
						tabIndex={0}
						css={{
							'&:focus': {
								outline: 'solid 3px var(--nsw-focus)',
								outlineOffset: 3,
							},
						}}
					>
						{glossaryData?.title}
					</span>
				) : (
					<span>
						{children({
							href: '',
						})}
					</span>
				)}

				<Popper
					id={id}
					ref={refPopper}
					open={open}
					anchorEl={anchorEl}
					className="z-[201] w-max max-w-[260px] sm:w-[416px] h-auto"
					popperOptions={{
						modifiers: popperModifiers,
					}}
					placement="top"
					disablePortal
					css={{
						borderRadius: 'var(--nsw-border-radius)',
						'&:focus': {
							outline: 'solid 3px var(--nsw-focus)',
							outlineOffset: 3,
						},
					}}
					tabIndex={-1}
				>
					<TrapFocus open={open} disableAutoFocus>
						<div tabIndex={-1}>
							<div
								ref={refArrow}
								className={clsx(
									'absolute',
									String`[[data-popper-placement=top]_&]:bottom-[-6px]`,
									String`[[data-popper-placement=bottom]_&]:top-[-6px]`,
								)}
							>
								<div className="w-[12px] h-[12px] bg-nsw-grey-02 border-nsw-grey-02 transform rotate-45"></div>
							</div>
							<Paper
								elevation={8}
								className={clsx(
									'p-4 pt-0 w-full h-full text-white bg-nsw-grey-02 border-nsw-grey-02 rounded-[var(--nsw-border-radius)]',
									String`[&_.RichtextLinkGlossaryNew__term]:text-nsw-white`,
								)}
								sx={{
									fontSize: 'var(--nsw-font-size-xs)',
									[theme.breakpoints.down('lg')]: {
										borderRadius: 0,
									},
								}}
							>
								<div className="flex justify-end nsw-toggletip__header">
									<button
										type="button"
										className="nsw-icon-button text-white ml-2 mt-1.5 -mr-2.5"
										aria-label="Close popup"
										onClick={handleClose}
									>
										<span className="sr-only">
											Close popup
										</span>
										<Icon
											icon="ic:baseline-close"
											width={16}
											height={16}
										/>
									</button>
								</div>
								<div className="mb-4 bold">
									{stateGlossaryData?.data?.title}
								</div>
								{stateGlossaryData?.data?.description ? (
									<RichText
										className={clsx(
											'text-white [&_a]:text-[#e6e9f0]',
											'[&_a:visited]:text-[#ddd1e8]',
											'[&_a:hover]:bg-[rgba(230,233,240,0.1)]',
											'[&_a:hover]:outline-[rgba(230,233,240,0.1)]',
										)}
										richTextElement={normalizeRichtextElementFromAzure(
											stateGlossaryData.data.description,
										)}
										linkedItems={
											stateGlossaryData.data.description
												.modular_content
												? convertLinkedItemsArrayToRecordFromAzure(
														stateGlossaryData.data
															.description
															.modular_content,
												  )
												: {}
										}
										mappings={mappings}
										rootGlossaryPopoverData={
											rootGlossaryPopoverData ||
											stateGlossaryData
										}
										setGlossaryPopoverParentData={
											setGlossaryData
										}
									/>
								) : null}
							</Paper>
						</div>
					</TrapFocus>
				</Popper>
			</span>
		</ClickAwayListener>
	)
}
