import { getApiDomain } from '@/lib/curriculum-azure-functions'
import {
	FetchLinkFromAzureResponse,
	FetchLinksFromAzureParam,
} from '@/types/fetchLinksFromAzure'
import { commonFetch } from '@/utils/fetchUtils'

export const fetchLinks = async ({
	codenames = [],
	preview = false,
}: FetchLinksFromAzureParam) => {
	return await commonFetch<
		FetchLinkFromAzureResponse,
		FetchLinksFromAzureParam
	>(`${getApiDomain(preview)}${process.env.NEXT_PUBLIC_DCAPI_PATH_LINKS}`, {
		codenames,
	})
}
