import {
	IContentItem,
	IContentItemsContainer,
	IContentItemSystemAttributes,
} from '@kontent-ai/delivery-sdk'

export default function convertLinkedItemsArrayToRecordFromAzure(
	modular_content: (Pick<IContentItem, 'elements'> &
		IContentItemSystemAttributes)[],
) {
	return modular_content
		.filter((item) => !!item?.codename)
		.reduce((acc, item) => {
			acc[item.codename] = {
				...item,
				elements: item.elements,
				system: item,
			} as IContentItem
			return acc
		}, {} as IContentItemsContainer)
}
