import { ResourceContainer } from '@/kontent/content-types'
import { TaxoSyllabustype } from '@/kontent/taxonomies'
import { TaxoStageWithLifeSkill, TaxoStageYearWithLifeSkill } from '@/types'
import { getTaxoCodenames, negatePredicate } from '@/utils'
import { getYearsFromStages } from '@/utils/stage_year'

/**
 * Checks if a resource's syllabus type is life skills
 */
export const matchesLifeSkillsType = (syllabusType: TaxoSyllabustype) =>
	syllabusType === 'life_skills'

/**
 * Checks if a resource's syllabus type is standard curriculum (non-life skills)
 */
export const matchesNonLifeSkillsType = negatePredicate(matchesLifeSkillsType)

/**
 * Checks if a resource's stage is in stages 4-6 range
 */
export const matchesStages456 = (stage: string) =>
	['stage_4', 'stage_5', 'stage_6'].includes(stage)

/**
 * Creates a filter function that matches resource containers based on:
 * - Stage/year compatibility
 * - Life skills vs regular syllabus type
 * - Special handling for Stage 6
 *
 * @param isStage6Syl - Whether the syllabus is stage 6
 * @param stageId - The stage ID to filter by
 * @param yearId - The year ID to filter by
 * @returns A predicate function that returns true if a resource container matches the criteria
 */
export const createResourceContainerFilter =
	(
		isStage6Syl: boolean,
		stageId: TaxoStageWithLifeSkill,
		yearId: TaxoStageYearWithLifeSkill,
	) =>
	(rc: ResourceContainer) => {
		const rcStages = getTaxoCodenames(rc.elements.stages__stages)
		const rcYears = getTaxoCodenames(rc.elements.stages__stage_years)
		const rcSyllabusType = getTaxoCodenames(rc.elements.syllabus_type)

		// Handle life skills case
		if (stageId === 'life_skills') {
			return (
				rcStages.some(matchesStages456) &&
				rcSyllabusType.some(matchesLifeSkillsType)
			)
		}

		// Early return for non-regular syllabus
		if (!rcSyllabusType.some(matchesNonLifeSkillsType)) return false

		// Handle Stage 6 case
		if (stageId === 'stage_6' && isStage6Syl) {
			return rcYears.some((s) => s === yearId)
		}

		// Handle other stages
		const years = getYearsFromStages([stageId])
		return rcYears.some((s) => years.includes(s))
	}
