import { Glossary } from '@/kontent/content-types'
import { contentTypes } from '@/kontent/project/contentTypes'
import { getItemsFeed } from '@/lib/api'
import { Mapping } from '@/types'

export default async function getAllGlossaryPageMappings(preview = false) {
	const response = await getItemsFeed<Glossary>({
		type: contentTypes.glossary.codename,
		preview,
	})
	const mappings = response.items.map((item) => {
		const mapping: Mapping = {
			params: {
				pageTitle: item.elements.title.value,
				slug: ['resources', 'glossary', item.elements.slug.value],
				isCanonical: true,
				navigationItem: item.system,
			},
		}

		return mapping
	})
	return mappings
}
