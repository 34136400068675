import { UiTabs } from '@/kontent/content-types'
import {
	Tabs as NswTabs,
	TabItem,
	TabItemWrapper,
	TabSection,
} from 'nsw-ds-react'
import { useEffect, useRef } from 'react'
import type { RichtextSectionProps } from '.'
import { getLinkedItems } from '../contexts/KontentHomeConfigProvider'
import RichText from '../RichText'

export default function UiTabsComponent({
	linkedItem,
	linkedItems,
	mappings,
}: RichtextSectionProps<UiTabs>) {
	const { items: _items } = linkedItem.elements
	const items = getLinkedItems(_items, linkedItems)
	const refNswTabs = useRef(null)

	useEffect(() => {
		if (refNswTabs.current) {
			// @ts-ignore
			console.log(refNswTabs.current)

			refNswTabs.current
				.querySelectorAll('.nsw-tabs__link')
				.forEach((tab) => {
					tab.classList.add('js-tabs-fixed')
				})
		}
	}, [])

	return items?.length ? (
		<div ref={refNswTabs}>
			<NswTabs>
				<TabItemWrapper>
					{items.map((tab) => {
						return (
							<TabItem
								key={tab.system.id}
								title={tab.elements.title.value}
								urlHash={`section-${tab.system.id}`}
							></TabItem>
						)
					})}
				</TabItemWrapper>
				{items.map((item) => {
					return (
						<TabSection
							key={item.system.id}
							urlHash={`section-${item.system.id}`}
						>
							<RichText
								data-kontent-elements-id={item.system.id}
								richTextElement={item.elements.content}
								linkedItems={linkedItems}
								mappings={mappings}
							/>
						</TabSection>
					)
				})}
			</NswTabs>
		</div>
	) : null
}
